import FetchServices from "./FetchServices";
import apiService from "./ApiServices";

export default function MainServices() {
  const { AxiosService } = FetchServices();
  const Services = {
    adminSignin: async function (details) {
      let API = apiService.adminLogin;
      let payload = {
        ...details,
      };
      let type = "POST";
      const res = await AxiosService(API, payload, false, type);
      return res;
    },

    getDashboard: async function (details) {
      let API = apiService.getDashboard;
      let payload = {
        ...details,
      };
      let type = "GET";
      const res = await AxiosService(API, payload, true, type);
      return res;
    },

    getUsersList: async function (details) {
      let API = apiService.getUsersList;
      let payload = {
        ...details,
      };
      let type = "GET";
      const res = await AxiosService(API, payload, true, type);
      return res;
    },

    getAppVersion: async function (details) {
      let API = apiService.getAppVersion;
      let payload = {
        ...details,
      };
      let type = "GET";
      const res = await AxiosService(API, payload, true, type);
      return res;
    },

    updateAppVersion: async function (details) {
      let API = apiService.updateAppVersion;
      let payload = {
        ...details,
      };
      let type = "POST";
      const res = await AxiosService(API, payload, true, type);
      return res;
    },

    getAllServices: async function (details) {
      let API = apiService.getAllServices;

      let payload = {};
      let type = "GET";
      const res = await AxiosService(API, payload, true, type);
      return res;
    },

    getFinesList: async function (details) {
      let API = apiService.getFinesList;

      let payload = {};
      let type = "GET";
      const res = await AxiosService(API, payload, true, type);
      return res;
    },

    getRenwalLicense: async function (details) {
      let API = apiService.getRenwalLicense;

      let payload = {};
      let type = "GET";
      const res = await AxiosService(API, payload, true, type);
      return res;
    },

    getServiceUtlizationByMonth: async function (details) {
      let API = apiService.getServiceUtlizationByMonth;

      let payload = {};
      let type = "GET";
      const res = await AxiosService(API, payload, true, type);
      return res;
    },

    getServiceUtilizationByState: async function (details) {
      let API = apiService.getServiceUtilizationByState;

      let payload = {};
      let type = "GET";
      const res = await AxiosService(API, payload, true, type);
      return res;
    },

    getDreamTecServices: async function (details) {
      let API = apiService.getDreamTecServices + "?service=" + details.service;
      let payload = {};
      let type = "GET";
      const res = await AxiosService(API, payload, true, type);
      return res;
    },

    getCompletedDreamTechServices: async function (details) {
      let API = apiService.getDreamTecServices +  "?service=" +
        details.service +
        "&StateKey=" + details.stateKey;
      let payload = {
         
      }
       let type = "GET";
      const res = await AxiosService(API, payload, true, type);
      return res;
    },

    getDreamTService: async function (details) {
      let API =
        apiService.getDreamTecServices +
        "?service=" +
        details.service +
        "&StateKey=" +
        details.StateKey +
        "&subService=" +
        details.subService;
      let payload = {
        ...details,
      };
      let type = "GET";
      const res = await AxiosService(API, payload, true, type);
      return res;
    },

    getServicesDreamtech: async function (details) {
      let API =
        apiService.getServicesDreamtech +
        "?subService=" +
        details.sub_service +
        "&date_from=" +
        details.date_from +
        "&date_to=" +
        details.date_to +
        "&date_to" +
        details.user +
        "&user" +
        details.location +
        "&location" +
        details.state_key +
        "&state_key";
      let payload = {
        ...details,
      };
      let type = "GET";
      const res = await AxiosService(API, payload, true, type);
      return res;
    },
    updateUserStatus: async function (details) {
      let API = apiService.updateUserStatus;

      let payload = {
        ...details,
      };
      let type = "POST";
      const res = await AxiosService(API, payload, true, type);
      return res;
    },
    getUserDetails: async function (details) {
      let API = apiService.getUserDetails;

      let payload = {
        ...details,
      };
      let type = "POST";
      const res = await AxiosService(API, payload, true, type);
      return res;
    },
    getDailyLogins: async function (details) {
      let API = apiService.getDailyLogins;

      let payload = {
        ...details,
      };
      let type = "GET";
      const res = await AxiosService(API, payload, true, type);
      return res;
    },
    getServicesCount: async function (details) {
      let API = apiService.getServicesCount;

      let payload = {
        ...details,
      };
      let type = "GET";
      const res = await AxiosService(API, payload, true, type);
      return res;
    },
    getServicesUtilizatinCount: async function (details) {
      let API = apiService.getServicesUtilizatinCount;

      let payload = {
        ...details,
      };
      let type = "GET";
      const res = await AxiosService(API, payload, true, type);
      return res;
    },
    serviceHeatMap: async function (details) {
      let API = apiService.serviceHeatMap;

      let payload = {
        ...details,
      };
      let type = "GET";
      const res = await AxiosService(API, payload, true, type);
      return res;
    },
    getUserMembership: async function (details) {
      let API = apiService.getUserMembership;

      let payload = {
        ...details,
      };
      let type = "POST";
      const res = await AxiosService(API, payload, true, type);
      return res;
    },
  };
  return Services;
}
