import React, { useEffect, useState } from "react";
import "./App.css";
import Main from "./Main";
 
import Toaster from "./assets/elements/Toaster";

import { HashRouter as Router } from "react-router-dom";
import { AuthProvider } from "./context/Auth";
import { ConfigProvider } from "./context/Config";

function App() {
  return (
    <ConfigProvider>
      <AuthProvider>
        <Router>
          <Toaster />
          <Main />
        </Router>
      </AuthProvider>
    </ConfigProvider>
  );
}

export default App;
